var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container text-center" },
      [
        _c("p", { staticClass: "my-5" }, [_vm._v("ページが見つかりません")]),
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("Top")])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }